import { keyframes } from '@emotion/react';
import { Box, Tag, Icon } from '@everlywell/ui-kit';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState, useEffect, useRef, useCallback } from 'react';

export interface CategoryPointer {
  angle: number;
  radius?: number;
  label: string;
  status: string;
}

export interface CategoriesPointersProps {
  pointers: CategoryPointer[];
  onPointerClick?: (label: string | null) => void;
}

const rippleKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
`;

const getPointerIcon = (status: string) => {
  const commonProps = {
    weight: 'fill',
    width: '16px',
    height: '16px',
  };

  const iconConfig =
    status === 'normal'
      ? { component: CheckCircle, color: 'viridian.light' }
      : { component: WarningCircle, color: 'terracotta.base' };

  return (
    <Icon as={iconConfig.component} color={iconConfig.color} {...commonProps} />
  );
};

const CategoriesPointers: React.FC<CategoriesPointersProps> = ({
  pointers,
  onPointerClick,
}) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const pointerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const labelRef = useRef<HTMLDivElement | null>(null);

  const handlePointerClick = (label: string) => {
    const newActiveCategory = activeCategory === label ? null : label;
    setActiveCategory(newActiveCategory);
    onPointerClick?.(newActiveCategory);
  };

  const resetState = useCallback(() => {
    setActiveCategory(null);
    onPointerClick?.(null);
  }, [onPointerClick]);

  // Close on click outside
  useEffect(() => {
    if (!activeCategory) return;

    const handleClickOutside = (event: MouseEvent) => {
      const clickedInsidePointer = pointerRefs.current.some(
        (ref) => ref && ref.contains(event.target as Node),
      );
      const clickedInsideLabel =
        labelRef.current && labelRef.current.contains(event.target as Node);

      if (!clickedInsidePointer && !clickedInsideLabel) {
        resetState();
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        resetState();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [activeCategory, resetState]);

  return (
    <>
      {pointers.map((pointer, index) => {
        const isActive = activeCategory === pointer.label;
        const icon = getPointerIcon(pointer.status);

        const hoverStyles = isActive
          ? {
              transform: `rotate(${pointer.angle}deg) translateY(${pointer.radius}px) scale(1.2)`,
            }
          : {
              transform: `rotate(${pointer.angle}deg) translateY(${pointer.radius}px) scale(1.2)`,
              animation: `${rippleKeyframes} 0.8s ease-out`,
            };

        return (
          <Box
            key={index}
            ref={(el) => (pointerRefs.current[index] = el)}
            position="absolute"
            transformOrigin="center center"
            transform={`rotate(${pointer.angle}deg) translateY(${pointer.radius}px)`}
            width={isActive ? ['12px', '32px'] : ['12px', '8px']}
            height={isActive ? ['12px', '32px'] : ['12px', '8px']}
            borderRadius="50%"
            backgroundColor="tints.white"
            cursor="pointer"
            boxShadow={
              isActive ? undefined : '0 0 0 8px rgba(255, 255, 255, 0.2)'
            }
            display="flex"
            alignItems="center"
            justifyContent="center"
            transition="all 0.4s ease-in-out"
            _hover={hoverStyles}
            role="button"
            aria-label={`${pointer.label} pointer`}
            opacity={isActive || activeCategory === null ? 1 : 0.6}
            onClick={() => handlePointerClick(pointer.label)}
          >
            {isActive && (
              <Box display="inherit" transform={`rotate(${-pointer.angle}deg)`}>
                {icon}
              </Box>
            )}
          </Box>
        );
      })}

      <AnimatePresence>
        {activeCategory && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            whiteSpace="nowrap"
            ref={labelRef}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              <Tag
                backgroundColor="tints.white"
                color="viridian.dark"
                borderRadius="full"
                fontSize="md"
                textAlign="center"
                data-test="category-pointer-label"
              >
                {activeCategory}
              </Tag>
            </motion.div>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};

export default CategoriesPointers;
