import { KitResult, EnterpriseAttributes } from 'common/utils/types';
import { User } from 'common/utils/types';
import KitList, { sortKitsResults } from 'components/KitList';
import RegisterKitHero from 'components/RegisterKitHero';
import SkeletonTestResults from 'components/SkeletonTestResults';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectUser } from 'store/selectors';

import Facts from './Facts';

function shouldShowRegisterKitHero(
  userEnterpriseAttributes: EnterpriseAttributes | undefined,
  hasKits: boolean,
): boolean {
  return (
    !userEnterpriseAttributes?.partner_slug &&
    !hasKits &&
    !!userEnterpriseAttributes?.enterprise_telehealth_program_slug
  );
}

type KitListResultsProps = {
  loading: boolean;
  inProgressKitResults: KitResult[];
  completedKitResults: KitResult[];
};

function KitListResults({
  loading,
  inProgressKitResults,
  completedKitResults,
}: KitListResultsProps): JSX.Element {
  const user: User | undefined = useSelector(makeSelectUser());
  const userEnterpriseAttributes = user?.enterprise_attributes;
  const hasKits = Boolean(
    inProgressKitResults.length || completedKitResults.length,
  );

  if (loading) {
    return <SkeletonTestResults />;
  }

  if (shouldShowRegisterKitHero(userEnterpriseAttributes, hasKits)) {
    return <RegisterKitHero />;
  }

  const sortedInprogress = sortKitsResults(inProgressKitResults);
  const sortedCompleted = sortKitsResults(completedKitResults);

  return (
    <>
      <KitList
        kitResults={sortedInprogress}
        heading="IN PROGRESS"
        testLabel="inprogress-results"
      />
      <KitList
        kitResults={sortedCompleted}
        heading="COMPLETED"
        testLabel="completed-results"
      />
      <Facts />
    </>
  );
}

export default KitListResults;
