import { Flex, Heading, IconButton, IconButtonProps } from '@everlywell/ui-kit';
import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { LinkProps } from 'react-router-dom';

export type HeaderProps = {
  title: string;
  backButton?: IconButtonProps & LinkProps;
};

const Header = ({ title, backButton }: HeaderProps) => (
  <Flex
    as="header"
    padding={4}
    alignItems="center"
    justifyContent="flex-start"
    gap={4}
  >
    {backButton && (
      <IconButton
        icon={<ArrowLeft />}
        {...backButton}
        colorScheme="whiteCS"
        aria-label="Left arrow icon"
        size="sm"
      />
    )}
    <Heading
      size="md"
      marginX={[0, 'auto']}
      transform={backButton ? ['none', 'translateX(-32px)'] : undefined}
    >
      {title}
    </Heading>
  </Flex>
);

export default Header;
