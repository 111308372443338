import { Badge, colors } from '@everlywell/leaves';
import { isSimplifiedMembershipPlanSectionActive } from 'common/utils/flags';
import { MembershipProgram, ProgramDisplayNames } from 'common/utils/types';
import React from 'react';

import DataContainer from './DataContainer';
import * as S from './styles';
import useWeightMembership from './useWeightMembership';

type SettingsContent = {
  title: string;
  cancelationText: string;
  cancelationLink: string;
};

const CONTENT: Record<MembershipProgram, SettingsContent> = {
  weight_management: {
    title: ProgramDisplayNames['weight-management-full'],
    cancelationText:
      'You can cancel your membership after 3 or 12 months (depending on your plan). Review our cancellation policy',
    cancelationLink:
      'https://support.everlywell.com/article/1857-weight-care-faq#What-is-your-cancellation-and-refund-policy-3HGU3',
  },
  control: {
    title: ProgramDisplayNames['everlywell-plus'],
    cancelationText:
      'For cancellations, review our cancellation policy',
    cancelationLink:
      'https://support.everlywell.com/article/664-how-do-i-cancel-my-membership',
  },
  health360: {
    title: ProgramDisplayNames['health360'],
    cancelationText:
      'For cancellations, review our cancellation policy',
    cancelationLink:
      'https://support.everlywell.com/article/1870-everlywell-360-membership#How-do-I-cancel-my-Everlywell-360-membership--O6PXG',
  },
};

export type MembershipPlanInfoProps = {
  programPlanType: MembershipProgram;
};

const MembershipPlanInfo = ({ programPlanType }: MembershipPlanInfoProps) => {
  const { weightMembershipData } = useWeightMembership();
  const { membershipStatus } = weightMembershipData || {};

  const textContent = CONTENT[programPlanType];

  if (!isSimplifiedMembershipPlanSectionActive()) {
    return (
      <S.MembershipPlanInfoContainer>
        <S.Title>{textContent.title}</S.Title>
        <S.ContentContainer>
          <S.MembershipInfoContainer>
            <Badge backgroundColor={colors.green3} text={membershipStatus} />
            <DataContainer />
          </S.MembershipInfoContainer>
          <S.CancellingDisclaimer>
            {textContent.cancelationText}{' '}
            <S.Link
              target="_blank"
              href={textContent.cancelationLink}
            >
              here
            </S.Link>
            . Please email our Customer Experience team at{' '}
            <S.Link href="mailto:contact@everlywell.com">
              contact@everlywell.com
            </S.Link>{' '}
            for more details.
          </S.CancellingDisclaimer>
        </S.ContentContainer>
      </S.MembershipPlanInfoContainer>
    );
  }

  return (
    <S.MembershipPlanInfoContainer>
      <S.Title>{textContent.title}</S.Title>
      {textContent.cancelationText}{' '}
      <S.Link
        target="_blank"
        href={textContent.cancelationLink}
      >
        here
      </S.Link>
      . Please email our Customer Experience team at{' '}
      <S.Link href="mailto:contact@everlywell.com">
        contact@everlywell.com
      </S.Link>{' '}
      for more details.
    </S.MembershipPlanInfoContainer>
  );
};

export default MembershipPlanInfo;
