import {
  Box,
  Text,
  Icon,
  Heading,
  Button,
  Link,
  theme,
} from '@everlywell/ui-kit';
import { WarningCircle, CheckCircle } from '@phosphor-icons/react';
import React from 'react';

export type CategoryCardProps = {
  abnormal: number;
  detailsPage: string;
  normal: number;
  title: string;
};

const setCircleBackground = (
  isAbnormal: boolean,
  abnormalColor: string,
  normalColor: string,
): React.CSSProperties => ({
  background: `radial-gradient(ellipse at center, rgba(${
    isAbnormal ? abnormalColor : normalColor
  }), transparent 75%)`,
  borderRadius: '50%',
  bottom: '-103px',
  filter: 'blur(4px)',
  height: '240px',
  position: 'absolute',
  width: '240px',
  zIndex: 0,
});

const CategoryCard: React.FC<CategoryCardProps> = ({
  abnormal,
  detailsPage,
  normal,
  title,
}) => {
  const markers = [
    {
      count: abnormal,
      label: 'Abnormal',
      color: 'terracotta.base',
      icon: WarningCircle,
    },
    {
      count: normal,
      label: 'In range',
      color: 'viridian.light',
      icon: CheckCircle,
    },
  ];

  return (
    <Box
      alignItems="center"
      as={Link}
      background="tints.lightCream"
      border="1px solid"
      borderColor="tints.white"
      borderRadius={20}
      display="flex"
      flexDirection="column"
      href={detailsPage}
      height={[240, 265, null, null]}
      overflow="hidden"
      paddingBlock={8}
      paddingInline={6}
      position="relative"
      width="100%"
      maxWidth={[null, 352, null, null]}
      _hover={{
        boxShadow: theme.shadows.tooltip,
        textDecoration: 'none',
      }}
    >
      <Heading
        fontSize={['2xl', null, '3xl']}
        fontWeight="medium"
        lineHeight="shorter"
        marginBottom={2}
        textAlign="center"
      >
        {title}
      </Heading>
      <Box display="flex" flexWrap="wrap" color="tints.uiGrey" columnGap={2}>
        {markers
          .filter(({ count }) => count > 0)
          .map(({ count, label, color, icon: IconComponent }) => (
            <Box key={label} display="flex" alignItems="center">
              <Icon
                as={IconComponent}
                boxSize={16}
                color={color}
                marginInlineEnd={1}
                weight="fill"
              />
              <Text>
                {count} {label}
              </Text>
            </Box>
          ))}
      </Box>
      <div
        style={setCircleBackground(
          abnormal > 0,
          '223, 43, 23, 0.6',
          '0, 247, 132, 0.8',
        )}
      />
      <Button
        colorScheme="whiteCS"
        fontSize={[14, null, 16]}
        marginTop="auto"
        size="sm"
        zIndex={1}
      >
        Explore results
      </Button>
    </Box>
  );
};

export default CategoryCard;
